import { Authenticator, Button, Card, Flex, Grid, Heading } from '@aws-amplify/ui-react';
import React, { useState } from 'react';

import { Companies } from './modules/Companies';
import TestUser from './modules/TestUser';
import Users from './modules/Users';

function App() {
  const [view, setView] = useState<'users' | 'companies' | 'testUser'>('users');
  return (
    <>
      <Heading level={1} style={{ textAlign: 'center', marginBottom: 40, marginTop: 40 }}>
        Stringline Admin
      </Heading>

      <Authenticator hideSignUp>
        {({ signOut, user }) => {
          if (!user) {
            return <></>;
          }

          return (
            <main>
              <Grid paddingLeft="5%" paddingRight="5%" columnGap="1rem" templateColumns="10em 1fr">
                <Card>
                  <Flex direction="column">
                    <Button onClick={() => setView('users')} variation="link">
                      Users
                    </Button>
                    <Button onClick={() => setView('companies')} variation="link">
                      Companies
                    </Button>
                    <Button onClick={() => setView('testUser')} variation="link">
                      Test User
                    </Button>
                    <Button onClick={signOut} variation="link">
                      Sign out
                    </Button>
                  </Flex>
                </Card>
                <Card>
                  {view === 'users' && <Users />}
                  {view === 'companies' && <Companies />}
                  {view === 'testUser' && <TestUser />}
                </Card>
              </Grid>
            </main>
          );
        }}
      </Authenticator>
    </>
  );
}

export default App;
