import { Auth } from 'aws-amplify';
import axios from 'axios';

import { getEnv } from './env';

async function getUserToken(): Promise<string> {
  const userSession = await Auth.currentSession();
  const idToken = userSession.getIdToken().getJwtToken();
  const { pool } = await Auth.currentAuthenticatedUser();

  return `${idToken} ${pool.clientId}`;
}

const apiUrl = getEnv('REACT_APP_API_URL');

export async function fetchUsers() {
  const userToken = await getUserToken();

  return axios.get(`${apiUrl}/admin/users`, {
    headers: {
      Authorization: userToken,
    },
  });
}

export async function fetchCompanies() {
  const userToken = await getUserToken();

  return axios.get(`${apiUrl}/admin/companies`, {
    headers: {
      Authorization: userToken,
    },
  });
}

export async function createTestUser(email: string) {
  const userToken = await getUserToken();

  return axios.post(
    `${apiUrl}/admin/testUser`,
    {
      email,
    },
    {
      headers: {
        Authorization: userToken,
      },
    }
  );
}
