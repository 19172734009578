import React, {useEffect, useState} from "react";
import {Loader, Table, TableBody, TableCell, TableHead, TableRow, TextField,} from "@aws-amplify/ui-react";
import {fetchUsers} from "../admin.service";


export function Users() {
  const [users, setUsers] = useState<[any] | []>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    fetchUsers().then((response) => {
      setUsers(response.data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!search) {
      setFilteredUsers(undefined)
      return;
    }
    setFilteredUsers(users.filter((user) => String(user.email).includes(search)));
  }, [search, users]);


  if (isLoading) {
    return <Loader size="large"/>;
  }

  return (
    <>
      <div style={{marginBottom: 20}}>
      <TextField
        labelHidden
        label="Search by email"
        placeholder="Search by email"
        onChange={(q) => setSearch(q.target.value)}
        value={search}
      />
      </div>
      <Table highlightOnHover variation="striped">
        <TableHead>
          <TableRow>
            <TableCell as="th">Name</TableCell>
            <TableCell as="th">Email</TableCell>
            <TableCell as="th">Onboarded</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(filteredUsers || users).map((user) => (
            <TableRow key={user.id}>
              <TableCell>
                {user.firstName} {user.lastName}
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.companyId ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default Users;
