import { Button, Flex, TextField } from '@aws-amplify/ui-react';
import React, { useState } from 'react';

import { createTestUser } from '../admin.service';

const TestUser = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    setIsLoading(true);
    const confirmation = window.confirm('Do you really want to wipe this account and create test data?');
    if (!confirmation) {
      setIsLoading(false);
      return;
    }
    createTestUser(email)
      .then(() => {
        setIsLoading(false);
        alert('Done!');
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };
  return (
    <Flex>
      <TextField
        labelHidden
        label="User email"
        placeholder="User email"
        onChange={({ target }) => setEmail(target.value)}
        value={email}
      />
      <Button onClick={handleSubmit} isLoading={isLoading}>
        Create test data
      </Button>
    </Flex>
  );
};

export default TestUser;
